/* montserrat-regular - latin */

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/montserrat-v24-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''), url('../webfonts/montserrat-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v24-latin-regular.svg#Montserrat') format('svg');
    /* Legacy iOS */
}


/* montserrat-700 - latin */

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/montserrat-v24-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''), url('../webfonts/montserrat-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v24-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v24-latin-700.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

/* purgecss start ignore */

@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/aos/dist/aos.css';

/* purgecss end ignore */

@import 'typography';
@import 'layout/all';
@import 'blocks/_all';
@import 'single/_all';

main{
    overflow: hidden;
}