$primary: #00CFCA;
$secondary: #000000;
$red: #E7194F;
$blue: #00CFCA;
$teal: #008999;
$yellow: #D0D600;
$blue-dark: #021D49;
$white: #fff;
$black: #021D49;
$grey: #F6F9FE;
$grey-dark: #979797;
$menu-dropdown-background: $primary;
$menu-dropdown-border-color: $blue;
$theme-colors: ( "primary": $primary, "red": $red, "blue": $blue, "blue-dark": $blue-dark, "teal": $teal, "yellow": $yellow, "white": $white, "black": $black, "grey": $grey, "grey-dark": $grey-dark, );
$font-family-base: 'Montserrat',
sans-serif;
$display-font-weight: 400;
$headings-font-family: 'Montserrat',
sans-serif;
$headings-font-weight: 700;
$headings-color: $primary;
$enable-negative-margins: true;
$spacer: 1rem;
$spacers: ( 0: 0, 1: $spacer / 4, 2: $spacer / 2, 3: $spacer, 4: $spacer * 1.5, 5: $spacer * 3, 6: $spacer * 4, 7: $spacer * 5, 8: $spacer * 6, 9: $spacer * 7, 10: $spacer * 8, 11: $spacer * 9, 12: $spacer * 10, 13: $spacer * 11, 14: $spacer * 12, 15: $spacer * 13, 16: $spacer * 14, );
$list-group-item-padding-y: $spacer / 2;
$list-group-item-padding-x: $spacer / 2;
$accordion-color: $black;
$accordion-bg: none;
$accordion-border-color: $black;
$accordion-border-radius: 0;
$accordion-border-width: 0;
$accordion-button-bg: transparent;
$accordion-button-color: $black;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $red;
$accordion-button-focus-border-color: $red;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: 1.25rem;
$accordion-icon-color: $red;
$accordion-icon-active-color: $red;
$accordion-icon-transition: transform .2s ease-in-out;
$accordion-icon-transform: rotate(180deg);
// $accordion-button-active-icon:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17.678' height='17.678' viewBox='0 0 17.678 17.678'><g id='arrow_prev' data-name='arrow/prev' transform='translate(16.617) rotate(90)'><path id='Path_2' data-name='Path 2' d='M0,0H11V11' transform='translate(7.778 0) rotate(45)' fill='none' stroke='#{$accordion-icon-active-color}' stroke-miterlimit='10' stroke-width='3'/></g></svg>");
// $accordion-button-icon:                   url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='17.678' height='17.678' viewBox='0 0 17.678 17.678'><g id='arrow_prev' data-name='arrow/prev' transform='translate(16.617) rotate(90)'><path id='Path_2' data-name='Path 2' d='M0,0H11V11' transform='translate(7.778 0) rotate(45)' fill='none' stroke='#{$accordion-icon-active-color}' stroke-miterlimit='10' stroke-width='3'/></g></svg>");
$table-color: $black;
$table-bg: transparent;
$table-accent-bg: transparent;
$table-th-font-weight: 700;
$table-striped-color: $table-color;
$table-striped-bg-factor: .61;
$table-striped-bg: rgba(255, 255, 255, $table-striped-bg-factor);
$table-border-width: 0;